
<!-- YourPage.vue -->

<template>
  <div class="m">
    <Navbar />
     <div>
      <h2>Home Page</h2>
      <router-link to="/wordcount">Word Count</router-link>
     <div :style="{'margin-bottom':'20px'}"></div>
      <router-link to="/currency-exchange">currency-exchange</router-link>
    </div>
    <div  class="footer-contain">
    <Bottom/>
   </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Bottom from '@/components/Bottom.vue';

export default {
  components: {
    Navbar,
    Bottom,
  },
  name: 'Home',
};
  
</script>

<style scoped>
.m {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footer-contain {
  margin-top: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
}
h2 {
  margin-bottom: 20px;
}
</style>
