<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #69a9c0;
  margin-top: 0; 
  height: 100%;
  min-height: 100%;
}

a {
  margin-right: 10px;
}
  
html,body{
    height: 100%
}
body {
margin:0;
padding:0;
border:0
}
</style>
