<!-- Navbar.vue -->

<template>
  <div class="navbar">
    <img class="logo" :src="logoPath" alt="Logo" /> 
    <div class="nav-item" v-for="item in navItems" :key="item.id">
      <router-link :to="item.link">{{ item.label }}</router-link>
    </div>
  </div>
</template>

<script>
 
export default {
  data() {
    return {
      navItems: [
        { id: 1, label: 'Home', link: '/' },
        { id: 2, label: 'WordCount', link: '/wordcount' },
        { id: 3, label: 'CurrencyExchange', link: '/currency-exchange' },
        { id: 4, label: 'About', link: '/about' },
        // Add more items as needed
      ],
      logoPath: require('@/assets/picture/logo.png'), // 根据实际路径修改
    };
  },
};
</script>

/* Navbar.vue 中的样式 */
<style scoped>
.navbar {
  margin: 0;
  padding: 0;
  background-color: #333;
  display: flex;
  padding: 10px 10px; /* 保留你的其他样式 */
  margin-top: 0; /* 添加的样式，重置 margin-top */
}
  
.logo {
  margin-top: 0; /* 添加的样式，重置 margin-top */
  /*margin-buttom: 0; *//* 添加的样式，重置 margin-top */
  max-height: 25px; /* 根据需要调整 logo 的最大高度 */
  margin-right: 15px; /* 根据需要调整 logo 与导航项之间的间距 */
}
  
.nav-item {
  margin-left: 20px;
  margin-right: 15px;
}

.nav-item a {
  color: #ffffff;
  text-decoration: none;
}

.nav-item a:hover {
  text-decoration: underline;
}
</style>


