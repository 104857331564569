<!-- CurrencyExchange.vue -->

<template>
  <div class="m">
    <Navbar />
    <div class="exchange-cards">
      <div v-for="currency in exchangeRateData" :key="currency.id" class="exchange-card background-image">
        <div class="card-content" :style="{ 'background-image': `url(${require('@/assets/picture/exchange.png')}) `}">
          <div :style="{'margin-bottom':'20px'}">{{ currency.text }}</div>
          <div>Rate：1 {{ currency.name }} = {{ currency.usd }} USD</div>
          <div>Date：{{ currency.date }}</div>
        </div>
      </div>
    </div>

    <div class="user-input">
      <select v-model="selectedCurrency1" @change="onCurrencyChange">
        <option v-for="(value, key) in currencyList" :key="key" :value="key" v-if="value !== ''">
          {{ value }}
        </option>
      </select>


       <div class="exchange-icon">
        <!-- 这里放表示兑换的图片 -->
        <img src="@/assets/picture/exchange-icon.png" alt="Exchange Icon" />
      </div>

      <select v-model="selectedCurrency2" @change="onCurrencyChange">
        <option v-for="(value, key) in currencyList" :key="key" :value="key" v-if="value !== ''">
          {{ value }}
        </option>
      </select>

      <button @click="submit">Submit</button>
    </div>

    <div class="result-contain">
      <div class="result" v-if="exchangeResult">
        <h3 class="result-title">Exchange Result:</h3>
        <div></div>
        <p>
          <span>1 {{ exchangeResult.left }}</span>

          <span class="result-equals">= </span>
          <span class="result-right">{{ exchangeResult.number }}</span>
          <span> {{ exchangeResult.right }}</span>
        </p>
        <p>Date : {{ exchangeResult.date }}</p>
      </div>  
   </div>
   <div  class="footer-contain">
    <Bottom/>
   </div>
    
  </div>

</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Bottom from '@/components/Bottom.vue';
import { ref, reactive, watch } from 'vue'
import gsap from 'gsap'
const number = ref(0)
const  exchangeResult = reactive({
        number: 0,
        left  : "from",
        right : "to",
})
watch(
  number,
    (n) => {
        gsap.to(exchangeResult, { duration: 0.5, number: Number(n) || 0 })
  }
);
  
export default {
  components: {
    Navbar,
    Bottom,
  },
  data() {
    return {
      exchangeRateData: [
        { id: 1, name: 'eur', usd: 0.85, text: 'Euro to USD', date: '' },
        { id: 2, name: 'abt', usd: 0.75, text: 'Another Currency to USD', date: '' },
        { id: 3, name: 'cny', usd: 0.75, text: 'Chinese Yuan to USD', date: '' },
        { id: 4, name: 'eur', usd: 0.85, text: 'Euro to USD', date: '' },
        { id: 5, name: 'abt', usd: 0.75, text: 'Another Currency to USD', date: '' },
        { id: 6, name: 'cny', usd: 0.75, text: 'Chinese Yuan to USD', date: '' },
        // Add more currencies as needed
      ],
      currencyList: [],
      selectedCurrency1: null,
      selectedCurrency2: null,
      amount: null,
    };
  },
  methods: {
    fetchData(apiEndpoint, dataKey) {
      return this.$axios.get(apiEndpoint)
        .then(response => response.data)
        .catch(error => {
          console.error('Error fetching data:', error);
          throw error;
        });
    },
    async loadExchangeRateData() {
      for (const currency of this.exchangeRateData) {
        try {
          const response = await this.fetchData(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currency.name}/usd.json`, 'exchangeRateData');
          currency.usd = response.usd || 0; // Assuming the API response structure
          currency.date = response.date || '';
        } catch (error) {
          console.error(`Error fetching data for ${currency.name}:`, error);
        }
      }
    },
    async loadCurrencyList() {
      try {
        const response = await this.fetchData('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies.json', 'currencyList');
        // Update currencyList with the response data
        this.currencyList = response;
      } catch (error) {
        console.error('Error fetching currency list:', error);
      }
    },
    onCurrencyChange() {
      // Handle currency change if needed
    },
    submit() {
      // Make a request to get exchange result
      var url =  'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/'+this.selectedCurrency1+'/'+ this.selectedCurrency2+'.json';
      this.$axios.get(url)
        .then(response => {
          var t =this.selectedCurrency2;
          this.exchangeResult = response.data;
          this.exchangeResult.left  = this.selectedCurrency1;
          this.exchangeResult.right = this.selectedCurrency2;
          this.number = response.data[t];
          this.exchangeResult.date = response.data.date;
        })
        .catch(error => {
          console.error('Error fetching exchange rate:', error);
        });
    },
  },
  mounted() {
    // Fetch currency list and load exchange rate data
    this.loadCurrencyList();
    this.loadExchangeRateData();
  },
};
</script>

<style scoped>
.m {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footer-contain {
  margin-top: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
}
.exchange-cards {
  margin-top: 1%;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.exchange-card {
  width: 25%; /* 20px 是左右两个卡片之间的 margin */
  height: 35%; /* 调整为固定值，或根据需要设定 */
  margin: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(63, 50, 50, 0.1);
  box-sizing: border-box;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直居中文本内容 */
  align-items: center; /* 水平居中文本内容 */
  background-color: #fff;
  border-radius: 10px;
}

.card-content div {
  text-align: left; /* 让文本内容居中 */
  font-size: 18px; /* 设置字体大小 */
  line-height: 1.5; /* 设置行高，根据需要调整 */
  color: #8bdb56;
}

.card-content .usd {
  font-weight: bold; /* 让 USD 字段更加突出 */
  color: #66ff00; /* 设置颜色，你可以根据需要调整 */
}

.background-image {
  background-size: contain;
  background-repeat: no-repeat;
}

.user-input {
  margin-top: 5%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.exchange-icon img {
  width: 30px; /* 设置图片宽度，根据需要调整 */
  height: 25px; /* 设置图片高度，根据需要调整 */
  margin: 0 10px; /* 添加适当的外边距，使其与选择框有一定的间距 */
}
.user-input select {
  height: 32px;
}
.user-input button {
  margin-left: 20px;
  height: 32px;
  margin-bottom: 10px;
}
.result-contain{

 height: 100%;
}
.result {
  height: 30%;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  margin-top: 20px; /* 调整间距，根据需要调整 */
}

.result p {
  font-size: 18px; /* 调整字体大小，根据需要调整 */
  display: flex;
  align-items: center;
}
.result-title {
  margin-left: 0px;
  margin-right: 15%;
  width: 15%;
}
.result-equals {
  margin: 0 10px; /* 调整等号周围的间距，根据需要调整 */
  font-size: 24px; /* 调整等号后面字体的大小，根据需要调整 */
  color: #ff9900; /* 设置等号后面字体的颜色，根据需要调整 */
}
.result-right {
  margin: 0 10px; /* 调整等号周围的间距，根据需要调整 */
  font-size: 40px; /* 调整等号后面字体的大小，根据需要调整 */
  color: #0ce3e7; /* 设置等号后面字体的颜色，根据需要调整 */
}

</style>
