
<template>
  <div>
    <Navbar />
     <div>
      <textarea v-model="inputText" @input="countWords" placeholder="Paste your text here"></textarea>
      <div>Total words: {{ wordCount }}</div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
  
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      inputText: "",
      wordCount: 0,
    };
  },
  methods: {
    countWords() {
      const words = this.inputText.split(/\s+/).filter(word => word !== '');
      this.wordCount = words.length;
    },
  },
};
</script>

<style scoped>
textarea {
  width: 60%;
  height: 200px;
  margin-bottom: 10px;
  margin-top: 60px;
}
</style>
