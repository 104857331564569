import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Home from './components/Home.vue';
import WordCount from './components/WordCount.vue';
import CurrencyExchange from './components/CurrencyExchange.vue';
import axios from 'axios';

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.prototype.$axios = axios

const routes = [
  { path: '/', component: Home },
  { path: '/wordcount', component: WordCount },
  {
      path: '/currency-exchange',
      name: 'CurrencyExchange',
      component: CurrencyExchange,
    },
];

const router = new VueRouter({
  routes,
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

